import { cloneDeep } from "lodash";
import { computed, ComputedRef } from "vue";

import { TargetSegment } from "@/api/segments/typedefs";
import { SegmentValueType } from "@/components/targetingpicker/typedefs";
import { idSymbol } from "@/components/targetingpicker/utils/key";
import { MetaValue } from "@/components/targetingpicker/utils/metaValue";
import { InputOperatorOptions } from "@/components/targetingpicker/utils/options";
import { getOperatorFromPath } from "@/components/targetingpicker/utils/path";
import {
  getSegmentByName,
  SegmentNotFoundError,
} from "@/components/targetingpicker/utils/segments";
import { NodeObject } from "@/components/targetingpicker/utils/typedefs";
import { ReadonlyRef } from "@/lib/typing";

// Workaround for variables with `_fixed` suffix.
const fixedVariables = [
  "lastActive",
  "firstseen",
  "vpnLastActive",
  "liveScoresLastInteraction",
  "lastVisitedMiniPay",
  "lastVisitedRewards",
  "lastRewardsStreaks",
];

function makeUnsupportedSegment(name: string): TargetSegment {
  return {
    name: name,
    segmentType: "unsupported",
    order: 0,
    isRuntime: false,
    configuration: {
      label: "Broken segment",
      type: "unsupported",
    },
  };
}

export interface UseInputNodePayload {
  segments: ReadonlyRef<TargetSegment[]>;
  modelValue: ReadonlyRef<NodeObject>;
  path: ReadonlyRef<string | number>;
}

interface VarDict {
  var: string;
}

function isVarDict(x: unknown): x is VarDict {
  if (x && typeof x === "object" && "var" in x) {
    return typeof x.var === "string";
  }

  return false;
}

export function useInputNode(payload: UseInputNodePayload) {
  const segment = computed(() => {
    if (variableName.value === undefined) {
      return undefined;
    }
    try {
      const values = payload.modelValue.value[operatorName.value] as Record<
        string,
        unknown
      >;
      if (variableName.value === "leanplum_id" && values.length === 3) {
        return getSegmentByName(payload.segments.value, "bucket");
      }
      return getSegmentByName(payload.segments.value, variableName.value);
    } catch (error) {
      if (error instanceof SegmentNotFoundError) {
        return makeUnsupportedSegment(variableName.value);
      }
      throw error;
    }
  });

  const options = computed(() => {
    const matching = segment?.value?.configuration.matching;
    const rawOptions =
      matching !== undefined ? InputOperatorOptions[matching] : {};
    return Object.entries(rawOptions).map(([key, value]) => {
      return { id: key, text: value };
    });
  });

  const operatorName = computed(() => {
    return Object.keys(payload.modelValue.value)[0];
  });

  const variableName = computed(() => {
    const varDict = Object.values(
      payload.modelValue.value[operatorName.value] as Record<string, unknown>
    ).find(isVarDict);

    // TODO: Consider handling this on backend.
    const varName = varDict?.var || "";
    if (
      fixedVariables.indexOf(varName) !== -1 &&
      typeof firstVariableValue.value === "string"
    ) {
      return `${varName}_fixed`;
    }
    return varName;
  });

  const varDictIndex = computed(() => {
    return Object.values(
      payload.modelValue.value[operatorName.value] as Record<string, unknown>
    ).findIndex(isVarDict);
  });

  const firstVariableValue = computed(() => {
    const value = (
      payload.modelValue.value[operatorName.value] as Record<string, unknown>
    )[varDictIndex.value + 1];
    if (
      typeof value !== "string" &&
      typeof value !== "number" &&
      !MetaValue.isMetaValue(value) &&
      value !== null
    ) {
      return undefined;
    }

    return value;
  });

  const secondVariableValue = computed(() => {
    const value = (
      payload.modelValue.value[operatorName.value] as Record<string, unknown>
    )[varDictIndex.value + 2];
    if (
      typeof value !== "string" &&
      typeof value !== "number" &&
      !MetaValue.isMetaValue(value) &&
      value !== null
    ) {
      return undefined;
    }

    return value;
  });

  const parentOperator = computed(() => {
    if (typeof payload.path.value !== "string") {
      return undefined;
    }
    return getOperatorFromPath(payload.path.value);
  });

  const updateValue = (value: SegmentValueType, index = 0) => {
    let convertedValue: SegmentValueType = value;
    if (
      segment?.value?.configuration.type === "number" ||
      segment?.value?.configuration.type === "bucket"
    ) {
      convertedValue = Number(value);
      if (isNaN(convertedValue)) {
        convertedValue = null;
      }
    }

    const newValue = cloneDeep(payload.modelValue.value);
    (newValue[operatorName.value] as Record<string, unknown>)[
      varDictIndex.value + 1 + index
    ] = convertedValue;

    return newValue;
  };

  const updateOperator = (value: string) => {
    let newValue = cloneDeep(payload.modelValue.value);
    newValue = {
      [idSymbol]: Reflect.get(payload.modelValue.value, idSymbol),
      [value]: Object.values(newValue)[0],
    };
    return newValue;
  };

  const fromSegment: ComputedRef<undefined | TargetSegment> = computed(() => {
    const fromSegment: undefined | TargetSegment = cloneDeep(segment.value);
    if (fromSegment === undefined) {
      return undefined;
    }
    fromSegment.configuration.type = segment.value?.configuration.to_type;
    fromSegment.configuration.placeholder =
      segment.value?.configuration.from_placeholder;
    fromSegment.configuration.handler_type = "input_limiter";
    return fromSegment;
  });

  const toSegment: ComputedRef<undefined | TargetSegment> = computed(() => {
    const toSegment: undefined | TargetSegment = cloneDeep(segment.value);
    if (toSegment === undefined) {
      return undefined;
    }
    toSegment.configuration.type = segment.value?.configuration.to_type;
    toSegment.configuration.placeholder =
      segment.value?.configuration.to_placeholder;
    toSegment.configuration.handler_type = "input_limiter";
    return toSegment;
  });

  return {
    updateValue,
    updateOperator,
    parentOperator,
    segment,
    options,
    operatorName,
    firstVariableValue,
    secondVariableValue,
    variableName,
    fromSegment,
    toSegment,
  };
}
