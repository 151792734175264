import { computed, Ref } from "vue";

import { TargetType } from "@/api/target/typedefs";
import { usePermissions } from "@/composables/usePermissions";
import { getProductLine } from "@/lib/productLine";

export function useTargetPermissions(payload: {
  targetType: Ref<TargetType>;
  productLineId: Ref<number>;
}) {
  const { hasInAppPermission, hasPushMessagePermission } = usePermissions();

  const productLine = computed(() =>
    getProductLine(payload.productLineId.value)
  );

  const hasChangePermission = computed(() => {
    if (payload.targetType.value === "pushnotifications") {
      return hasPushMessagePermission(
        "change_messagetarget",
        productLine.value
      );
    } else {
      return hasInAppPermission("change_inapptarget", productLine.value);
    }
  });

  const hasAddPermission = computed(() => {
    if (payload.targetType.value === "pushnotifications") {
      return hasPushMessagePermission("add_messagetarget", productLine.value);
    } else {
      return hasInAppPermission("add_inapptarget", productLine.value);
    }
  });

  return {
    hasChangePermission,
    hasAddPermission,
  };
}
