import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "row justify-between items-center q-gutter-y-sm full-width" }
const _hoisted_2 = {
  class: "text-h6 q-mr-lg",
  "data-test": "pm.modal.title"
}
const _hoisted_3 = { class: "flex justify-end q-gutter-x-sm" }
const _hoisted_4 = { class: "q-col-gutter-md" }
const _hoisted_5 = { class: "row q-col-gutter-md" }
const _hoisted_6 = { class: "col-12 col-sm-6" }
const _hoisted_7 = { class: "col-12 col-sm-6" }
const _hoisted_8 = { class: "row q-col-gutter-md justify-end" }
const _hoisted_9 = { class: "col-12 col-sm-6 row q-gutter-y-sm column" }
const _hoisted_10 = { class: "col-12 col-sm-6 col-sm-offset-6" }
const _hoisted_11 = { class: "row q-col-gutter-md" }

import QForm from 'quasar/src/components/form/QForm.js';;
import { Ref, ref, toRefs, watch } from "vue";

import { TargetDetail, TargetType } from "@/api/target/typedefs";
import { useTargetPermissions } from "@/components/shared/target/useTargetPermissions";
import QueryPreview from "@/components/targetingpicker/QueryPreview.vue";
import TargetingPicker from "@/components/targetingpicker/TargetingPicker.vue";
import { isNodeObject } from "@/components/targetingpicker/utils/guards";
import { isNonEmptyString } from "@/lib/rules/stringValidatior";

import { useTargetsForm } from "./useTargetsForm";


export default /*@__PURE__*/_defineComponent({
  __name: 'TargetsModalForm',
  props: {
    productId: {},
    productLineId: {},
    targetType: {},
    modelValue: {},
    disabled: { type: Boolean },
    isClone: { type: Boolean },
    cloneFromId: {}
  },
  emits: ["update:modelValue", "submit", "cancel", "clone"],
  setup(__props: any, { emit: __emit }) {

const formRef: Ref<QForm | null> = ref(null);

const props = __props;

const { productLineId, targetType } = toRefs(props);

const emit = __emit;

const { value, timezones } = useTargetsForm(
  props.modelValue,
  props.cloneFromId
);
const { hasChangePermission } = useTargetPermissions({
  productLineId: productLineId,
  targetType: targetType,
});

watch(
  value,
  (newValue) => {
    emit("update:modelValue", newValue);
  },
  { deep: true }
);

const onSubmit = () => {
  emit("submit");
};

const onCancel = () => {
  emit("cancel");
};

const onClone = () => {
  emit("cancel");
  emit("clone");
};

return (_ctx: any,_cache: any) => {
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_card_actions = _resolveComponent("q-card-actions")!
  const _component_q_separator = _resolveComponent("q-separator")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_select = _resolveComponent("q-select")!
  const _component_q_checkbox = _resolveComponent("q-checkbox")!
  const _component_q_tooltip = _resolveComponent("q-tooltip")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_card_section = _resolveComponent("q-card-section")!

  return (_openBlock(), _createBlock(_unref(QForm), {
    ref_key: "formRef",
    ref: formRef,
    class: "q-gutter-y-md q-pa-md",
    greedy: "",
    onSubmit: onSubmit
  }, {
    default: _withCtx(() => [
      _createVNode(_component_q_card_actions, null, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              (!_unref(hasChangePermission))
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _createTextVNode(" View targeting ")
                  ], 64))
                : (_ctx.isClone)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                      _createTextVNode(" Clone targeting ")
                    ], 64))
                  : (_unref(value).targetId !== '')
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                        _createTextVNode(" Edit targeting ")
                      ], 64))
                    : (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
                        _createTextVNode("New targeting")
                      ], 64))
            ]),
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_q_btn, {
                label: "Cancel",
                color: "secondary",
                style: {"order":"1"},
                onClick: onCancel
              }),
              (_unref(hasChangePermission))
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    (_unref(value).targetId !== '' && !_ctx.isClone)
                      ? (_openBlock(), _createBlock(_component_q_btn, {
                          key: 0,
                          label: "Clone",
                          color: "info",
                          style: {"order":"0"},
                          onClick: onClone
                        }))
                      : _createCommentVNode("", true),
                    _createVNode(_component_q_btn, {
                      label: "Submit",
                      color: "primary",
                      disable: _ctx.disabled,
                      style: {"order":"2"},
                      onClick: formRef.value?.submit
                    }, null, 8, ["disable", "onClick"])
                  ], 64))
                : _createCommentVNode("", true)
            ])
          ])
        ]),
        _: 1
      }),
      _createVNode(_component_q_separator),
      _createVNode(_component_q_card_section, null, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createVNode(_component_q_input, {
                  modelValue: _unref(value).targetName,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(value).targetName) = $event)),
                  disable: _ctx.disabled || !_unref(hasChangePermission),
                  outlined: "",
                  label: "Target name",
                  rules: [_unref(isNonEmptyString)]
                }, null, 8, ["modelValue", "disable", "rules"])
              ]),
              _createElementVNode("div", _hoisted_7, [
                _createVNode(_component_q_select, {
                  modelValue: _unref(value).targetTimeZone,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(value).targetTimeZone) = $event)),
                  disable: _ctx.disabled || !_unref(hasChangePermission),
                  options: _unref(timezones),
                  outlined: "",
                  label: "Time zone",
                  "emit-value": "",
                  "map-options": ""
                }, null, 8, ["modelValue", "disable", "options"])
              ])
            ]),
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("span", null, [
                  _createVNode(_component_q_checkbox, {
                    modelValue: _unref(value).inactive,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_unref(value).inactive) = $event)),
                    disable: _ctx.disabled || !_unref(hasChangePermission)
                  }, {
                    default: _withCtx(() => _cache[6] || (_cache[6] = [
                      _createTextVNode(" Hide ")
                    ])),
                    _: 1
                  }, 8, ["modelValue", "disable"]),
                  _createVNode(_component_q_icon, {
                    name: "help",
                    size: "xs",
                    class: "q-ml-xs"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_tooltip, null, {
                        default: _withCtx(() => _cache[7] || (_cache[7] = [
                          _createTextVNode("Removes targeting from targeting lists")
                        ])),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ])
              ]),
              _createElementVNode("div", _hoisted_10, [
                _createVNode(_component_q_checkbox, {
                  modelValue: _unref(value).includePremiumUsers,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_unref(value).includePremiumUsers) = $event)),
                  disable: _ctx.disabled || !_unref(hasChangePermission),
                  label: "Include premium users"
                }, null, 8, ["modelValue", "disable"])
              ])
            ])
          ])
        ]),
        _: 1
      }),
      _createVNode(_component_q_separator),
      _createVNode(_component_q_card_section, null, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_11, [
            (_unref(isNodeObject)(_unref(value).segments))
              ? (_openBlock(), _createBlock(TargetingPicker, {
                  key: 0,
                  modelValue: _unref(value).segments,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_unref(value).segments) = $event)),
                  disable: _ctx.disabled,
                  "product-line-id": props.productLineId,
                  "product-id": props.productId,
                  type: props.targetType,
                  dictionary: _unref(value).dictionary,
                  "read-only": !_unref(hasChangePermission)
                }, null, 8, ["modelValue", "disable", "product-line-id", "product-id", "type", "dictionary", "read-only"]))
              : _createCommentVNode("", true)
          ])
        ]),
        _: 1
      }),
      (_unref(value).query && !_ctx.isClone)
        ? (_openBlock(), _createBlock(_component_q_separator, { key: 0 }))
        : _createCommentVNode("", true),
      (_unref(value).query && !_ctx.isClone)
        ? (_openBlock(), _createBlock(QueryPreview, {
            key: 1,
            modelValue: _unref(value).query,
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_unref(value).query) = $event)),
            "show-header": false
          }, null, 8, ["modelValue"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 512))
}
}

})