import { computed, ComputedRef } from "vue";

import { SegmentValue, TargetSegment } from "@/api/segments/typedefs";
import { FilterEventPayload, ScrollEventPayload } from "@/api/typedefs";
import { SegmentValueType } from "@/components/targetingpicker/typedefs";
import { MetaValue } from "@/components/targetingpicker/utils/metaValue";
import { ReadonlyRef } from "@/lib/typing";

export interface UseVariableInputPayload {
  segment: ReadonlyRef<TargetSegment>;
  fetchOptions: (event: FilterEventPayload) => Promise<void>;
  fetchedValues: ReadonlyRef<SegmentValue[]>;
  fetchMoreOptions: (event: ScrollEventPayload) => Promise<void>;
  modelValue: ReadonlyRef<SegmentValueType>;
  dictionary?: ReadonlyRef<Record<string, Record<string, unknown>> | undefined>;
  increaseDisplayValue?: ReadonlyRef<number | undefined> | undefined;
}

export function useVariableInput(payload: UseVariableInputPayload) {
  const onFilterValues = async (event: FilterEventPayload) => {
    if (payload.segment?.value?.configuration.handler_type === "static_list") {
      return;
    }
    await payload.fetchOptions({
      value: event.value,
      onSuccess: event.onSuccess,
      onError: event.onError,
    });
  };

  const onVirtualScroll = async (event: ScrollEventPayload) => {
    if (payload.segment?.value?.configuration.handler_type === "static_list") {
      return;
    }
    await payload.fetchMoreOptions({
      to: event.to,
      ref: event.ref,
    });
  };

  const primitiveValue: ComputedRef<string | number | null> = computed(() => {
    return MetaValue.isMetaValue(payload.modelValue.value)
      ? payload.modelValue.value.valueOf()
      : (payload.modelValue.value as string | number | null);
  });

  const currentOptionLabel: ComputedRef<string | null> = computed(() => {
    const segmentName = payload.segment.value?.name;
    const value = primitiveValue.value;

    // Retrieve label from value if available
    if (MetaValue.isMetaValue(payload.modelValue.value)) {
      return String(payload.modelValue.value.getMetadata("label"));
    }

    if (segmentName !== undefined && value !== undefined && value !== null) {
      return (
        (payload.dictionary?.value?.[segmentName]?.[value] as string) ||
        String(value)
      );
    }

    return null;
  });

  const valueOptions = computed(() => {
    if (payload.segment?.value?.configuration.handler_type === "static_list") {
      if (typeof payload.segment.value?.configuration.options !== "object") {
        throw new Error("Unexpected type of options value");
      }
      return Object.values(payload.segment.value?.configuration.options).map(
        (value) => ({
          id: value,
          text: value,
        })
      );
    }

    if (payload.modelValue.value === undefined) {
      return [];
    }

    const options: Array<{ id: string | number; text: string }> =
      payload.fetchedValues.value.map((value: SegmentValue) => ({
        id: value.id,
        text: value.name,
      }));

    return options;
  });

  const placeholderLength = computed(() => {
    if (payload.segment.value.configuration.placeholder) {
      return payload.segment.value.configuration.placeholder.length + "ch";
    }
    return "0ch";
  });

  const segmentDictionary = computed(() => {
    const segmentName = payload.segment.value?.name;
    return segmentName ? payload.dictionary?.value?.[segmentName] : undefined;
  });

  const displayValue = computed(() => {
    let value = primitiveValue.value;
    if (
      payload.segment.value.configuration.type === "number" &&
      payload.increaseDisplayValue?.value !== undefined &&
      value !== null
    ) {
      value = Number(value) + payload.increaseDisplayValue.value;
    }
    return value;
  });

  const updateValue = (value: string | number | null): SegmentValueType => {
    if (
      // Typeof wouldn't work because q-input returns a string.
      payload.segment.value.configuration.type === "number" &&
      payload.increaseDisplayValue?.value !== undefined &&
      value !== null
    ) {
      value = Number(value) - payload.increaseDisplayValue.value;
    }

    // Store label in value
    const selectedOption = valueOptions.value.find(
      (option: { id: string | number; text: string }) => option.id === value
    );
    if (selectedOption !== undefined && value !== null) {
      const metaValue = new MetaValue(value);
      metaValue.setMetadata("label", selectedOption.text);
      return metaValue;
    }

    return value;
  };

  return {
    onFilterValues,
    onVirtualScroll,
    valueOptions,
    placeholderLength,
    segmentDictionary,
    displayValue,
    primitiveValue,
    updateValue,
    currentOptionLabel,
  };
}
