import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, isRef as _isRef, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "flex justify-center items-center q-py-lg"
}

import QSpinner from 'quasar/src/components/spinner/QSpinner.js';import useQuasar from 'quasar/src/composables/use-quasar.js';;
import useDialogPluginComponent from 'quasar/src/composables/use-dialog-plugin-component.js';;
import type { DialogChainObject } from "quasar";
import { onMounted, toRefs } from "vue";

import { TargetType } from "@/api/target/typedefs";
import TargetsModalForm from "@/components/targets/TargetsModalForm.vue";

import {
  TargetFormErrorResponse,
  TargetFormSuccessResponse,
  useTargetModal,
} from "./useTargetModal";


export default /*@__PURE__*/_defineComponent({
  __name: 'TargetModal',
  props: {
    productId: {},
    productLineId: {},
    targetType: {},
    targetId: {},
    isClone: { type: Boolean }
  },
  setup(__props: any) {

const props = __props;

const { productId, productLineId, targetType, targetId, isClone } =
  toRefs(props);

const { dialogRef, onDialogOK, onDialogCancel } = useDialogPluginComponent();

const $q = useQuasar();

const {
  isSubmitting,
  isLoading,
  targetValue,
  fetchData,
  onSubmit,
  onClone,
  cloneFromId,
} = useTargetModal({
  productId: productId,
  productLineId: productLineId,
  targetType: targetType,
  targetId: targetId,
  onSuccess: (payload: TargetFormSuccessResponse) => {
    onDialogOK({
      event: "success",
      payload: payload,
    });
  },
  onError: (payload: TargetFormErrorResponse) => {
    $q.notify({
      type: "negative",
      message: payload.message,
    });
  },
  isClone: isClone,
  onClone: (payload: DialogChainObject) => {
    onDialogOK({
      event: "clone",
      payload: payload,
    });
  },
});

onMounted(fetchData);

return (_ctx: any,_cache: any) => {
  const _component_q_card = _resolveComponent("q-card")!
  const _component_q_dialog = _resolveComponent("q-dialog")!

  return (_openBlock(), _createBlock(_component_q_dialog, {
    ref_key: "dialogRef",
    ref: dialogRef,
    "full-width": "",
    class: "form-max-width"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_q_card, { class: "form-max-width justify-center" }, {
        default: _withCtx(() => [
          (!_unref(isLoading))
            ? (_openBlock(), _createBlock(TargetsModalForm, {
                key: 0,
                modelValue: _unref(targetValue),
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(targetValue) ? (targetValue).value = $event : null)),
                "target-type": _unref(targetType),
                "product-id": _unref(productId),
                "product-line-id": _unref(productLineId),
                disabled: _unref(isSubmitting),
                "is-clone": _unref(isClone),
                "clone-from-id": _unref(cloneFromId),
                class: "q-mx-auto",
                onSubmit: _unref(onSubmit),
                onCancel: _unref(onDialogCancel),
                onClone: _unref(onClone)
              }, null, 8, ["modelValue", "target-type", "product-id", "product-line-id", "disabled", "is-clone", "clone-from-id", "onSubmit", "onCancel", "onClone"]))
            : (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createVNode(_unref(QSpinner), {
                  "data-test": "ia.loading",
                  size: "xl",
                  color: "primary"
                })
              ]))
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 512))
}
}

})